(function() {
  var evalHash, mapInit, scrollTo;

  evalHash = function() {
    if (location.hash) {
      scrollTo(location.hash);
      return history.replaceState(null, null, location.href.replace(/#.*$/, ""));
    }
  };

  scrollTo = function(_target, _speed, _callback) {
    var position, target;
    target = _target instanceof jQuery ? _target : $(_target);
    if (target.prop("tagName") === 'BODY') {
      position = 0;
    } else {
      position = target.position().top;
    }
    return document.screen_scroll_target.animate({
      scrollTop: position
    }, _speed || 500, 'easeOutQuart', _callback);
  };

  mapInit = function() {
    var latlng, map, marker, markerOptions, options, style, styledMapType;
    latlng = new google.maps.LatLng(35.706325, 139.782039);
    options = {
      zoom: 17,
      center: new google.maps.LatLng(35.706325, 139.782039),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      type: false,
      zoomControl: true,
      scrollwheel: false
    };
    style = [
      {
        featureType: 'transit',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'administrative',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'poi',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'water',
        stylers: [
          {
            "color": "#e9e9e9"
          }, {
            "lightness": 100
          }
        ]
      }, {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }, {
        featureType: 'road',
        stylers: [
          {
            saturation: -100
          }
        ]
      }, {
        featureType: 'landscape',
        stylers: [
          {
            saturation: -100
          }, {
            lightness: 50
          }
        ]
      }
    ];
    map = new google.maps.Map(document.getElementById('map'), options);
    markerOptions = {
      position: latlng,
      map: map,
      title: 'i.lab'
    };
    marker = new google.maps.Marker(markerOptions);
    styledMapType = new google.maps.StyledMapType(style, options);
    map.mapTypes.set('noText', styledMapType);
    return map.setMapTypeId('noText');
  };

  $(function() {
    var adjustColumnHeight, isSP, ua;
    ua = navigator.userAgent;
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {

    } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
      $('head>meta[name="viewport"]').attr('content', 'width=1024');
    }
    document.screen_scroll_target = $('html,body');
    evalHash();
    if ($('body').is('.jobs')) {
      adjustColumnHeight = (function(_this) {
        return function() {
          var calcHeight;
          calcHeight = function(parent, children) {
            var getFirstColumnHeight, height, i, limit, results;
            getFirstColumnHeight = function(num) {
              var heightOfFirstColumn;
              heightOfFirstColumn = 0;
              children.each(function(index) {
                if (num <= index) {
                  return false;
                }
                return heightOfFirstColumn += $(this).height();
              });
              return heightOfFirstColumn;
            };
            i = 1;
            limit = parent.offset().left + parent.width();
            results = [];
            while (i <= children.length) {
              height = getFirstColumnHeight(i);
              parent.height(height);
              if ((children.last().offset().left + children.last().width()) <= limit) {
                break;
              }
              results.push(i++);
            }
            return results;
          };
          return calcHeight($('.achievements .rows'), $('.achievements .row'));
        };
      })(this);
      adjustColumnHeight();
      isSP = $('#ham').is(':visible');
      $(window).on('resize', (function(_this) {
        return function() {
          var _isSP;
          _isSP = $('#menu_btn').is(':visible');
          if (isSP !== _isSP) {
            adjustColumnHeight();
            return isSP = _isSP;
          }
        };
      })(this));
    }
    return $(document).on('click', 'a[href*=#]', function(e) {
      var id;
      e.preventDefault();
      id = "#" + $(this).attr('href').split('#')[1];
      return scrollTo(id);
    }).on('click', '#menu_btn', function() {
      return $('header nav').addClass('visible');
    }).on('click', 'header nav.left', function(e) {
      var t;
      t = $(e.target);
      if (t.is('nav.left') || t.is('#close_btn') || 0 < t.parent('#close_btn').length) {
        return $('header nav').removeClass('visible');
      }
    });
  });

}).call(this);
