# ハッシュがついていたらその位置までスクロールしてURLを戻す
evalHash = ->
  if location.hash
    scrollTo(location.hash)
    history.replaceState(null, null, location.href.replace(/#.*$/,""))

scrollTo = (_target, _speed, _callback)->
  target = if _target instanceof jQuery then _target else $(_target)

  if target.prop("tagName") == 'BODY'
    position = 0
  else
    position = target.position().top
  document.screen_scroll_target.animate {scrollTop: position}, _speed || 500, 'easeOutQuart', _callback

mapInit = ->
  latlng = new (google.maps.LatLng)(35.706325,139.782039)
  options =
    zoom: 17
    center: new (google.maps.LatLng)(35.706325,139.782039)
    mapTypeId: google.maps.MapTypeId.ROADMAP
    disableDefaultUI: true
    type: false
    zoomControl: true
    scrollwheel: false
  style = [
    {
      featureType: 'transit'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'administrative'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'poi'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'water'
      elementType: 'labels'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'water'
      stylers: [ {"color": "#e9e9e9"},{"lightness": 100}]
    }
    {
      featureType: 'road'
      elementType: 'labels'
      stylers: [ { visibility: 'off' } ]
    }
    {
      featureType: 'road'
      stylers: [ { saturation: -100 } ]
    }
    {
      featureType: 'landscape'
      stylers: [
        { saturation: -100 }
        { lightness: 50 }
      ]
    }
  ]
  # generate map
  map = new (google.maps.Map)(document.getElementById('map'), options)
  markerOptions =
    position: latlng
    map: map
    title: 'i.lab'
  marker = new (google.maps.Marker)(markerOptions)
  styledMapType = new (google.maps.StyledMapType)(style, options)
  map.mapTypes.set 'noText', styledMapType
  map.setMapTypeId 'noText'

$ ->

  ua = navigator.userAgent
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)
    # do nothing
  else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0)
    # TODO 正規表現でwidthだけ変える
    # content = $('head>meta[name="viewport"]').attr('content')
    # new_content = content.replace(/width=\d+^\d/, 'width=1024')
    # console.log new_content
    $('head>meta[name="viewport"]').attr('content', 'width=1024')


  # 画面スクロールをさせる時のターゲットをブラウザ毎に設定
  document.screen_scroll_target = $('html,body')

  evalHash()

  # GoogleMapsAPIのapiKeyをもらえたら（ilab側で会社のクレジットを将来的に作る予定らしいので）復活させる
  # mapInit() if $('body').is('.contact')

  if $('body').is('.jobs')
    # これまでのコンサルティング実績のところをPCで３列、SPで２列にする
    adjustColumnHeight = () =>
      # -----------------------------------------------------------
      # parentの高さを調整する処理
      # 正確には、領域内に収まるかどうかを判定しているだけ
      calcHeight = (parent, children) =>
        # 1列目に何children入れるかを渡すと、並び替え後の列数を返す関数
        getFirstColumnHeight = (num) ->
          heightOfFirstColumn = 0
          children.each (index) ->
            return false if num <= index
            # 指定されたchildren数
            heightOfFirstColumn += $(this).height()
          return heightOfFirstColumn
        # 最小で3列に1列目のchildren数を調べる
        i = 1
        limit = parent.offset().left + parent.width()
        while i <= children.length
          height = getFirstColumnHeight(i)
          parent.height(height)
          # 枠内に収まっているか判定し、収まっていたらそこで終了
          break if (children.last().offset().left + children.last().width()) <= limit
          i++
      # -----------------------------------------------------------
      calcHeight($('.achievements .rows'), $('.achievements .row'))

    # 読み込み時に実行
    adjustColumnHeight()
    # リサイズしてPC/SPが切り替わった際に再度実行
    isSP = ($('#ham').is(':visible'))
    $(window).on 'resize', () =>
      _isSP = ($('#menu_btn').is(':visible'))
      if isSP != _isSP
        adjustColumnHeight()
        isSP = _isSP

  $(document)
    .on 'click', 'a[href*=#]', (e)->
      e.preventDefault()
      id = "#"+$(this).attr('href').split('#')[1]
      scrollTo id
    .on 'click', '#menu_btn', ->
      $('header nav').addClass 'visible'
    .on 'click', 'header nav.left', (e)->
      t = $(e.target)
      if t.is('nav.left') or t.is('#close_btn')or 0<t.parent('#close_btn').length
        # close_btnか、overlay部分がクリックされたらナビゲーションを消す
        # close_btn>iがtargetの場合も加味すること
        $('header nav').removeClass 'visible'